import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import i18next from "i18next";
import Loading from "./components/loading";
import { getMenu } from "../../products/redux/selectors";
import { getProduct, getLoading, getProductFail } from "../redux/selectors";
import { ProductActionCreators } from "../redux/actions";
import { IProductState } from "../redux/reducers";
import { clickStream, getLangPrefix } from "../../../helpers/helpers"

import ProductS from "./components/productS";
import ProductC from "./components/productC";

import BasketPopup from "../../basketPopup/container/basketPopup";
import CurrentPage from "../../../layouts/common/currentPage";
import { MenuActionCreators } from "../../../layouts/main/header/redux/actions";
import { Link, Redirect } from "react-router-dom";
import { getRecommendedProductsData } from "../../home/redux/selectors";
import { LANGUAGE_PREFIX } from "../../../utils/constants/variables";

interface Props extends IProductState {
    match?: any,
    menuList: any,
    product: any,
    menuItem?: any,
    recommendedProducts?: any
    error?: any
}

interface State {
    showBasket: boolean,
    product: any
    error?: any
}

class Product extends React.Component<Props & typeof MenuActionCreators & typeof ProductActionCreators, State> {
    state: State = {
        showBasket: false,
        product: {},
        error: ''
    };

    handleBasketToggle = () => {
        this.setState({ showBasket: !this.state.showBasket })
    };
    handelResetLocationSearch = () => {
        this.props.locationSearchDestroyedStart();
    }
    componentDidMount() {
        const { canonicalUrl, location, history, productStart, recommendedProductsStart } = this.props
        if (canonicalUrl && location.pathname !== canonicalUrl) {
            history.push(canonicalUrl)
        }

        const { match: { params: { productId = "" } } } = this.props;

        productStart({ productId, brandId: localStorage.selectedProductBrandId }, (product) => {
            this.setState({ product })
        });

        recommendedProductsStart()
        setTimeout(() => {
            this.props.setMenuItem({ ...this.props.menuItem, isProductPage: true })
        }, 0)
    };

    componentWillUnmount(): void {
        this.setState({
            showBasket: false,
            product: {},
            error: ''
        })
        this.props.productReset()
        this.props.setMenuItem({ ...this.props.menuItem })
    }

    componentWillReceiveProps(nextProps) {


        if (nextProps.error !== this.props.error || (this.props.error && this.props.error.includes('404'))) {
            this.setState({ error: nextProps.error })
        }
        if (nextProps.product && this.props.product && nextProps.product.productId !== this.props.product.productId) {
            const { match: { params: { productId = "" } } } = nextProps;
            clickStream(this.props.menuItem.id, productId, nextProps.product.productId)
        }

        if (nextProps.location.pathname !== this.props.location.pathname) {
            const { match: { params: { productId = "" } } } = nextProps;
            this.props.productStart({ productId, brandId: localStorage.selectedProductBrandId }, (product) => {
                this.setState({ product })
            });
        }
        if (nextProps.product.compositeCanonicalUrlForSingle
            && (nextProps.product.compositeCanonicalUrlForSingle !== this.props.product.compositeCanonicalUrlForSingle || nextProps.product.productId !== this.props.product.productId)) {
            nextProps.productCompositeStart(nextProps.product.compositeCanonicalUrlForSingle)
        }
    };

    getProductLoadingStatus = () => {
        const { loading } = this.props;
        const { product } = this.state;
        if (loading) return <Loading />;

        if (product === null) return <Redirect from="*" to={`${LANGUAGE_PREFIX}/404`} />;

        return <div className="errorBox">
            <h1 className="errorBox--Message">{i18next.t("Error.5")}</h1>
            {this.props.history.location.state ?
                <Link
                    className="errorBox--Btn main-btn"
                    to={`${getLangPrefix()}${this.props.history.location.state.backLink}`}
                >
                    {i18next.t("Error.6")}
                </Link> : " "
            }
        </div>
    }


    render() {
        const { showBasket, product } = this.state;
        const { menuItem, loading, recommendedProducts, error } = this.props;

        return (
            product ? (
                <div className="product">
                    {menuItem && Object.keys(menuItem).length ?
                        <CurrentPage selectedItem={menuItem} showDescription={false} productData={product} />
                        : ""
                    }
                    {product.productType == "S" &&
                        <ProductS
                            handleBasketToggle={this.handleBasketToggle}
                            data={product}
                            recommendedProducts={recommendedProducts}
                            menuItem={menuItem}
                            handelResetLocationSearch={this.handelResetLocationSearch} />
                    }

                    {product.productType == "C" &&
                        <ProductC handleBasketToggle={this.handleBasketToggle}
                            data={product}
                            menuItem={menuItem}
                            recommendedProducts={recommendedProducts} />
                    }
                    {showBasket &&
                        <BasketPopup handleBasketToggle={this.handleBasketToggle} />
                    }

                </div>
            ) : (
                this.getProductLoadingStatus()
            )
        )
    }
}

const mapStateToProps = (state: any): Props => {
    return {
        product: getProduct(state),
        loading: getLoading(state),
        menuList: getMenu(state),
        recommendedProducts: getRecommendedProductsData(state),
        error: getProductFail(state)
    };
};

const mapDispatchToProps = (dispatch): typeof ProductActionCreators => {
    return bindActionCreators({ ...ProductActionCreators, ...MenuActionCreators }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);

